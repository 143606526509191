const config = {

        AES_ENCRYPTION_KEY: "8b7e156228aed2a6abf7152985cf4f9c",
	LIA_CO_PILOT_CONFIG: {
		URL: 'lia-copilot-demo.shipsy.io',
	},
	OIDC_SELF_BASE_URL: '.demo.shipsy.io',
	SSO_REDIRECT_BASE_URL: 'https://dashboardlogindemo.shipsy.io',
	API_BASE_URL: "https://demodashboardapi.shipsy.in/api",
	BUSINESS_BOOKING_API_URL: "https://obbv2demo.shipsy.in",
	USE_HARDCODED_MASTER_DATA: false,
	LTL_BASE_URL: "https://ltlapidemo.shipsy.in",
	DOMESTIC_STATUS_FETCH_POLLING_FREQUENCY: 60000,
	// LOG_ROCKET_KEY: "ni1awe/ops-dashboard-demo",
	INTERCOM_KEY: "ntvhrj4m",
	LTL_POLLING_FREQUENCY: 60000,
	GOOGLE_MAPS_API_KEY: "AIzaSyAQZiRqv1QXltfG8td8qVlXKrFak1lXJyk",
	SENTRY_KEY: "https://84e3a8767fbf69ba3252907a8328c75c@sentry.shipsy.io//56",
	ENVIRONMENT: "demo",
	CONSIGNMENT_LABEL_URL: "https://elabel.shipsy.in",
	QUERYBUILDER_CONFIG: {
		BACKENDURL: "https://querybuilderdemoapi.shipsy.in",
		ANALYTICS_FRONTEND_URL: "analyticsdashboarddemo.shipsy.io",
	},
	SHOW_BOOKING: true,
	ANALYTICS_DATA: [
		{
			src: "https://datastudio.google.com/embed/reporting/1kfMxjB5WJUJHeSCS9-yq4HzMnKhEUBch/page/ui2o",
			colSpan: 24,
			height: "1400px",
		},
		{
			src: "https://datastudio.google.com/embed/reporting/1OOQkyPDsDm_PTunMfvEaCttIwjDZKFlP/page/tj6o",
			colSpan: 24,
			height: "1400px",
		},
		{
			src: "https://datastudio.google.com/embed/reporting/18tmjP5ZE_C9F2jAG4YZattzoMIYuZyNL/page/jp8o",
			colSpan: 24,
			height: "1400px",
		},
	],
	RENDER_PERSISTENT_IFRAME_OPS: true,
	RAVEN_BASE_URL: "https://ravendemo.shipsy.in/api",
	API_PLAYGROUND_CONFIG: {
		URL: "https://demoapiplayground.shipsy.in",
	},
	ROUTING_PLAYGROUND_URL: "https://routingv2demo.shipsy.in",
	ONLINE_BOOKING_BASE_URL: "https://ebookingbackenddemo.shipsy.in",
	FIREBASE_CONFIG: {
		apiKey: "AIzaSyBS7-u48IAJzePDpwM-nbKNXULw8kD7HL4",
		authDomain: "notification-service-418009.firebaseapp.com",
		databaseURL: "https://notification-service-418009-default-rtdb.firebaseio.com",
		projectId: "notification-service-418009",
		storageBucket: "notification-service-418009.appspot.com",
		messagingSenderId: "413910393309",
		appId: "1:413910393309:web:18279bf70b4ef30ee9d634",
		measurementId: "G-9SKNTTVQEC"
	},
	FIREBASE_VAPID_KEY: "BMJxxP73j8ok0wOvo5Ak_qked8D9oZW4-FhMsqie5JGh08HUvWcWpQKuX7vT2KIzQ9ahK7Q8ujQdcs15G7y8m4A",
	FIRESTORE_DB_ID: "notification-service-demo",
CSP_VIOLATION_REPORT_URI: "https://demodashboard.shipsy.in:3002/report/demo-crm-logistics-frontend"
};

module.exports.default = config;
